'use strict';

Gri.module({
  name: 'card-alpha',
  ieVersion: null,
  $el: $('.card-alpha'),
  container: '.card-alpha',
  fn: function () {
    $('.card-alpha').hover(function(){
      $(this).addClass('hover');
    },function(){
      $(this).removeClass('hover');
    });
    $('.grayscale').gray();
  }
});
